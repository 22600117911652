import { React, useState } from 'react'
import './Footer.scss'

import { Alert } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import {
	facebook,
	instagram,
	telegram,
	tiktok,
	viber,
} from '../../Constants.js'

// ICONS
import call_beige from '../../images and icons/icons/call_beige.svg'
import {
	facebook_beige,
	inst_beige,
	tg_beige,
	tiktok_beige,
	viber_beige,
} from '../../images and icons/icons/index'
import logo_dark from '../../images and icons/icons/logo_dark.svg'

export default function Footer() {
	function copyText(event) {
		let number = event.target.textContent
		navigator.clipboard.writeText(number)
		setOpen(true)
	}

	const [open, setOpen] = useState(false)

	const handleSendErrorClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpen(false)
	}

	return (
		<>
			<div className='footer' id='contact'>
				<div className='footer__container container'>
					<div className='footer__leftSide'>
						<img src={logo_dark} alt='logo' className='footer__logo' />
						<div className='footer__text'>
							Репетиторський центр <b>MPLUS</b> <br /> Підготовка школярів з 1
							по 11 клас
							<div className='footer__offer'>
								<a
									href='/Offer_contract.docx'
									download='Договір_оферти_Репетиторський_центр_MPLUS_'
								>
									Договір оферти
								</a>
							</div>
						</div>
					</div>
					<div className='footer__rightSide'>
						<div className='footer__mediaContainer'>
							<a href={instagram}>
								<img
									src={inst_beige}
									alt='instagram'
									className='footer__mediaIcon'
								/>
							</a>
							<a href={facebook}>
								<img
									src={facebook_beige}
									alt='facebook'
									className='footer__mediaIcon'
								/>
							</a>
							<a href={telegram}>
								<img
									src={tg_beige}
									alt='telegram'
									className='footer__mediaIcon'
								/>
							</a>
							<a href={viber}>
								<img
									src={viber_beige}
									alt='viber'
									className='footer__mediaIcon'
								/>
							</a>
							<a href={tiktok}>
								<img
									src={tiktok_beige}
									alt='viber'
									className='footer__mediaIcon'
								/>
							</a>
						</div>

						<div className='footer__phone'>
							<img src={call_beige} alt='logo' className='footer__phoneIcon' />
							<div className='footer__phoneText' onClick={copyText}>
								+380 (98) 324 72 80
							</div>
						</div>

						<a href='#questionForm'>
							<div className='footer__btn'>ЗАЛИШИТИ ЗАЯВКУ</div>
						</a>
					</div>
				</div>

				<Snackbar
					open={open}
					autoHideDuration={4000}
					onClose={handleSendErrorClose}
				>
					<Alert
						severity='success'
						open={open}
						autoHideDuration={4000}
						onClose={handleSendErrorClose}
					>
						Номер скопійовано
					</Alert>
				</Snackbar>
			</div>
		</>
	)
}
