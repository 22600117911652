import React from 'react';
import './navigation.scss';

const Navigation = () => {
    return (
        <div className="header-navigation">
            <a href="#aboutUs" className="header-navigation__item">Про нас</a>
            <a href="#services" className="header-navigation__item">Предмети</a>
            <a href="#schoolMPlus" className="header-navigation__item">Про школу</a>
            <a href="#feedbacks" className="header-navigation__item">Відгуки</a>
            <a href="#vacancies" className="header-navigation__item">Для вчителів</a>
        </div>
    );
};

export default Navigation;