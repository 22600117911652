import { React, useState } from 'react'
import {
	baseUrl,
	botId,
	encodedToken,
	fileEndpoint,
} from '../../../Constants.js'
import loadingHTML from '../../../UI/loading/loading.js'
import cross from '../../../images and icons/icons/cross.svg'
import './Popup.scss'

// ICONS
import one from '../../../images and icons/icons/1+.svg'
import hundret from '../../../images and icons/icons/190.svg'
import clip from '../../../images and icons/icons/clip.svg'
import hat_dark from '../../../images and icons/icons/hat_dark.svg'
import tick from '../../../images and icons/icons/tick.svg'
import zoom from '../../../images and icons/icons/zoom.svg'

export default function Popup() {
	const [file, setFile] = useState(null)

	const handleFileChange = event => {
		const selectedFile = event.target.files[0]
		if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
			setFile(null)
			alert('Розмір файлу повинен бути не більше 5 МБ')
		} else {
			const allowedTypes = [
				'application/pdf',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'text/plain',
				'application/rtf',
				'text/markdown',
				'text/html',
			]
			const fileType = selectedFile.type
			if (allowedTypes.includes(fileType)) {
				setFile(selectedFile)
			} else {
				alert('Будь ласка, завантажте файл у дозволеному форматі')
			}
		}
	}
	function hidePopup() {
		document.getElementById('vacancies__popup').style.display = 'none'
		document.getElementById('overlay').style.display = 'none'
		document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
	}

	const [formData, setFormData] = useState({
		vacancy_name: '',
		vacancy_birthday: '',
		vacancy_email: '',
		vacancy_phone: '',
		vacancy_exp: '',
		vacancy_places: '',
	})

	const isValidPhone = phone => {
		// Валідатор для номера телефону
		return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
			phone
		) //eslint-disable-line
	}

	const handleChange = event => {
		const { id, value } = event.target
		setFormData(prevFormData => ({
			...prevFormData,
			[id]: value,
		}))
	}

	const handleSubmit = event => {
		event.preventDefault()

		// Перевірка, чи всі поля заповнені
		if (
			formData.vacancy_name &&
			formData.vacancy_birthday &&
			formData.vacancy_email &&
			formData.vacancy_phone &&
			formData.vacancy_exp &&
			formData.vacancy_places
		) {
			if (!isValidPhone(formData.vacancy_phone)) {
				alert('Будь ласка, введіть коректний номер телефону.')
				return
			}

			// Формування повідомлення для адміністраторів
			const message = `
        Нова вакансія викладача:
        ПІБ: ${formData.vacancy_name}
        Дата народження: ${formData.vacancy_birthday}
        E-mail: ${formData.vacancy_email}
        Телефон: ${formData.vacancy_phone}
        Досвід роботи: ${formData.vacancy_exp}
        Місця роботи: ${formData.vacancy_places}
      `

			// Відображення повідомлення адміністраторам
			sendData(message)

			// Очистити форму після відправки
			setFormData({
				vacancy_name: '',
				vacancy_birthday: '',
				vacancy_email: '',
				vacancy_phone: '',
				vacancy_exp: '',
				vacancy_places: '',
			})
		} else {
			alert('Будь ласка, заповніть всі поля.')
		}
	}

	function AnimateLoading() {
		let mainDiv = document.getElementById('vacancies__input_loading')
		mainDiv.getElementsByClassName('check')[0].classList =
			'check check-complete success'
		;[...mainDiv.getElementsByClassName('fill')].map(el => {
			el.classList = 'fill fill-complete success'
			return null
		})
		mainDiv.getElementsByClassName('path')[0].classList = 'path path-complete'

		setTimeout(function () {
			mainDiv.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
		}, 5000)

		return null
	}

	function sendData(message) {
		let btnElement = document.getElementById('vacancies__input_btn')
		btnElement.innerHTML = `<div id="vacancies__input_loading">${loadingHTML}</div>`

		fetch(baseUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				validator: encodedToken,
				chat_id: botId,
				message: message,
			}),
		})
			.then(response => response.json())
			.then(data => {})
			.catch(error => {
				btnElement.innerHTML = 'Спробуйте ще раз..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})

		const formData = new FormData()
		formData.append('file', file)

		fetch(fileEndpoint, {
			method: 'POST',
			body: formData,
		})
			.then(response => response.json())
			.then(data => {
				AnimateLoading()
			})
			.catch(error => {
				btnElement.innerHTML = 'Спробуйте ще раз..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})
	}

	return (
		<div className='popup container' id='vacancies__popup'>
			<div className='popup__titleContainer'>
				<div className='popup__title'>Вакансії для вчителів</div>
				<img
					src={cross}
					alt='exit'
					className='popup__cross'
					onClick={hidePopup}
				/>
			</div>

			<div className='vacancies__subTitle popup__subTitle'>
				Шукаємо вчителів у нашу сім’ю школи MPLUS
			</div>

			<div className='popup__iconContainer iconContainer'>
				<div className='iconContainer__block'>
					<img src={one} className='iconContainer__img' alt='icon' />
					<div className='iconContainer__text'>
						Досвід викладання(у тому числі і онлайн) від 1 року і більше
					</div>
				</div>
				<div className='iconContainer__block'>
					<img src={hundret} className='iconContainer__img' alt='icon' />
					<div className='iconContainer__text'>Склали ЗНО на 190+</div>
				</div>
				<div className='iconContainer__block'>
					<img src={hat_dark} className='iconContainer__img' alt='icon' />
					<div className='iconContainer__text'>
						Студенти вищих навчальних закладів або випускників з вищою
						навчальною освітою
					</div>
				</div>
				<div className='iconContainer__block'>
					<img src={tick} className='iconContainer__img' alt='icon' />
					<div className='iconContainer__text'>
						Готовність проходити навчання та вдосконалюватися
					</div>
				</div>
				<div className='iconContainer__block'>
					<img src={zoom} className='iconContainer__img' alt='icon' />
					<div className='iconContainer__text'>
						Досвід роботи на платформі zoom
					</div>
				</div>
			</div>

			<form
				className='popup__formContainer formContainer'
				onSubmit={handleSubmit}
			>
				<input
					className='formContainer__input'
					id='vacancy_name'
					required
					placeholder='Введіть ваше ПІБ'
					value={formData.vacancy_name}
					onChange={handleChange}
				/>
				<input
					className='formContainer__input'
					id='vacancy_birthday'
					required
					placeholder='Дата народження'
					type='date'
					value={formData.vacancy_birthday}
					onChange={handleChange}
				/>
				<input
					className='formContainer__input'
					id='vacancy_email'
					required
					placeholder='E-mail'
					type='email'
					value={formData.vacancy_email}
					onChange={handleChange}
				/>
				<input
					className='formContainer__input'
					id='vacancy_phone'
					required
					placeholder='Номер телефону'
					type='phone'
					value={formData.vacancy_phone}
					onChange={handleChange}
				/>
				<input
					className='formContainer__input'
					id='vacancy_exp'
					required
					placeholder='Досвід роботи'
					value={formData.vacancy_exp}
					onChange={handleChange}
				/>
				<input
					className='formContainer__input'
					id='vacancy_places'
					required
					placeholder='Місця роботи'
					value={formData.vacancy_places}
					onChange={handleChange}
				/>

				<label className='formContainer__input formContainer__input_file'>
					<input type='file' onChange={handleFileChange} />
					Додайте резюме
					<img src={clip} alt='clip' className='formContainer__clip' />
				</label>

				<button
					className='vacancies__btn'
					type='submit'
					id='vacancies__input_btn'
				>
					ЗАЛИШИТИ ЗАЯВКУ
				</button>
			</form>
		</div>
	)
}
