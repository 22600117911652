import React from "react";
import "../../Vacancies/Popup/Popup.scss";
import "./Popup.scss";
import cross from "../../../images and icons/icons/cross.svg";

export default function Popup({subject, sendInfo}){

  function hidePopup(){
    document.getElementById("services__popup_"+subject).style.display = "none";
    document.getElementById("overlay").style.display = "none";
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  }

  function checkPhone(phone){
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone)
  }

  function checkEmail(email){
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  function sendInformation(){
    let name = document.getElementById(subject+"_services_name").value
    let phone = document.getElementById(subject+"_services_phone").value
    let email = document.getElementById(subject+"_services_email").value

    if(name===""||phone===""||email==="") return;

    let oldInfo = JSON.parse(localStorage.getItem("info_"+subject));
    oldInfo.subject = subject === "eng" ? 
    "Англійська" : 
    (subject==="ukr" ? 
      "Українська" : 
      "Математика")
    oldInfo.name = name;
    oldInfo.phone = phone;
    oldInfo.email = email;


    if(!checkPhone(phone)) {
      alert("Будь ласка, введіть коректний номер телефону")
      return
    }
    if(!checkEmail(email)) {
      alert("Будь ласка, введіть коректний email")
      return
    }
    sendInfo(oldInfo, subject);
  }

  let name = subject === "eng" ? 
            "Англійської" : 
            (subject==="ukr" ? 
              "Української" : 
              "Математики")

  return (
    <div className="popup container" id={"services__popup_" + subject}>
      <div className="popup__titleContainer">
        <div className="popup__title">Залишайте заявку</div>
        <img
          src={cross}
          alt="exit"
          className="popup__cross"
          onClick={hidePopup}
        />
      </div>

      <div
        className="vacancies__subTitle popup__subTitle"
        id={"popup__subTitle_" + subject}
      >
        На навчання в школі MPLUS з {name}
      </div>

      <div className="popup__iconContainer iconContainer"></div>

      <form className="popup__formContainer formContainer">
        <input
          className="formContainer__input"
          id={subject + "_services_name"}
          required
          placeholder="Введіть ім'я"
        />
        <input
          className="formContainer__input"
          id={subject + "_services_email"}
          required
          placeholder="E-mail"
          type="email"
        />
        <input
          className="formContainer__input"
          id={subject + "_services_phone"}
          required
          placeholder="Номер телефону"
          type="phone"
        />

        <div 
          className="vacancies__btn" 
          onClick={sendInformation}
          id={"vacancies__input_button_"+subject}
        >
          ЗАЛИШИТИ ЗАЯВКУ
        </div>
      </form>
    </div>
  );
}