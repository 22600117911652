import React from 'react';
import "./Vacancies.scss";
import {motion} from "framer-motion";

import Popup from "./Popup/Popup.js";

export default function Vacancies() {
  
  function showPopup(){
    document.getElementById("overlay").style.display = "block";
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";

    if(window.innerWidth<1400){
      document.getElementById("vacancies__popup").style.display = "block";
    } else {
      document.getElementById("vacancies__popup").style.display = "flex";
    }

  }

  function hidePopup(){
    document.getElementById("services__popup_eng").style.display = "none";
    document.getElementById("services__popup_math").style.display = "none";
    document.getElementById("services__popup_ukr").style.display = "none";
    document.getElementById("vacancies__popup").style.display = "none";
    document.getElementById("overlay").style.display = "none";
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  }

  return (
    <div className="vacancies container blockMargin" id="vacancies">
      <motion.div 
        className="title"
        initial={{opacity: 0, x: 15}}
        whileInView={{opacity: 1, x: 0}}
        viewport={{ once: true }}
        transition={{
          delay: "0.2"
        }}
      >Вакансії для вчителів</motion.div>
      <div className="vacancies__subTitle">Шукаємо вчителів у нашу сім’ю</div>
      <div className="vacancies__text">
        Якщо Ви молодий, креативний вчитель з досвідом викладання (онлайн чи
        офлайн) від 1 року і більше, склали ЗНО на 190+, готові працювати на
        результат, вдосконалюватися та проходити нові навчання, <b> тоді Ви наш
        кандидат!</b>
      </div>
      <div className="vacancies__btn" onClick={showPopup}>ЗАЛИШИТИ ЗАЯВКУ</div>
      <Popup/>
      <div className="overlay" id="overlay" onClick={hidePopup}></div>
    </div>
  );
}