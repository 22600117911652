import React from "react";
import "./Media.scss";
import {instagram, facebook, telegram, viber, tiktok} from "../../Constants.js";
import { motion } from "framer-motion";

// ICONS
import fb_dark from "../../images and icons/icons/fb_dark.svg";
import tg_dark from "../../images and icons/icons/tg_dark.svg";
import inst_dark from "../../images and icons/icons/inst_dark.svg";
import vib_dark from "../../images and icons/icons/vib_dark.svg";
import {tiktok_dark} from "../../images and icons/icons";

export default function Media() {
  return (
    <div className="mediaBlock blockMargin">
      <div className="container mediaBlock__container">
        <div className="mediaBlock__title">
          Слідкуйте за нами в соц. мережах!
        </div>
        <div className="mediaBlock__icons">
          <motion.a
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: "0.2",
            }}
            viewport={{ once: true }}
            style={{ all: "unset" }}
            href={facebook}
          >
            <img src={fb_dark} alt="facebook" className="mediaBlock__icon" />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: "0.3",
            }}
            viewport={{ once: true }}
            style={{ all: "unset" }}
            href={telegram}
          >
            <img src={tg_dark} alt="telegram" className="mediaBlock__icon" />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: "0.4",
            }}
            viewport={{ once: true }}
            style={{ all: "unset" }}
            href={instagram}
          >
            <img src={inst_dark} alt="instagram" className="mediaBlock__icon" />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: "0.5",
            }}
            viewport={{ once: true }}
            style={{ all: "unset" }}
            href={viber}
          >
            <img src={vib_dark} alt="viber" className="mediaBlock__icon" />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: "0.5",
            }}
            viewport={{ once: true }}
            style={{ all: "unset" }}
            href={tiktok}
          >
            <img src={tiktok_dark} alt="viber" className="mediaBlock__icon" />
          </motion.a>
        </div>
      </div>
    </div>
  );
}
