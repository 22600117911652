import React from 'react';
import {motion, AnimatePresence} from "framer-motion";

const Question = ({question, answer, answer2, answer3, selected1, selected2, img, link, open, onClick, questionColor, answerColor}) => {

    const styleBlack = {background: "linear-gradient(180deg, #212121 0%, #0D0D0D 100%)", color: "#F2E0D1" }
    const styleGreen = {background: "#F2E0D1", color: "#212121" }

    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
        delay: "0.3",
      }}
      viewport={{ once: true }}
      className="questionDiv"
      >
        <AnimatePresence>
            <motion.div
                className={'questionBox'}
                onClick={onClick}
                style={open ? styleGreen : styleBlack}
                animate={open ? {height: 'auto'} : {height: "auto"}}
                transition={{duration: 0.4}}
            >
                <div className={'questionBox-text'}>

                    <h4 className="questionBox-text__question">{question}</h4>
                    <AnimatePresence>
                        {open &&
                            <motion.p
                                initial={{height: 0, opacity: 0}}
                                animate={{height: "auto", opacity: 1}}
                                exit={{height: 0, opacity: 0}}
                                transition={{duration: 0.4}}
                                className="questionBox-text__answer">{answer} <a href={link} className={"questionBox-text__selected"} ><b>{selected1}</b></a>  {answer3} </motion.p>}
                    </AnimatePresence>
                    <AnimatePresence>
                        {open &&
                            <motion.p
                                initial={{height: 0, opacity: 0}}
                                animate={{height: "auto", opacity: 1}}
                                exit={{height: 0, opacity: 0}}
                                transition={{duration: 0.4}}
                                className="questionBox-text__answer"><a href={link} className={"questionBox-text__selected"} ><b>{selected2}</b> </a>   {answer2}</motion.p>}
                    </AnimatePresence>
                </div>
                <motion.img
                    animate={open ? {rotate: 315} : {rotate: 180}}
                    transition={{duration: 0.4}}
                    onClick={onClick} className={'questionBox-text__img'} src={img} alt={img}></motion.img>
            </motion.div>
        </AnimatePresence>
        </motion.div>
    );
};

export default Question;