import { React, useState, useEffect } from "react";
import "./subjectBlock.scss";

import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { motion } from "framer-motion";

export default function SubjectBlock({ subject, delay }) {
  const [info, setInfo] = useState({
    class: 0,
    format: 0,
    lessons: 0,
  });
  const [open, setOpen] = useState(false);

  const [individualPrice, setIndividualPrice] = useState(0);
  const [dualPrice, setDualPrice] = useState(0);
  const [groupPrice, setGroupPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);

  let prices = {
    "1-4 клас": {
      Індивідуальне: 320,
      Парне: 0,
      Групове: 0,
    },
    "5-10 клас": {
      Індивідуальне: 360,
      Парне: 300,
      Групове: 250,
    },
    "11 клас": {
      Індивідуальне: 400,
      Парне: 340,
      Групове: 0,
    },
  };

  let selectedGrade = 0;
  let selectedLesson = 0;

  if (localStorage.getItem(subject + "_selectedGrade") !== null)
    selectedGrade = localStorage.getItem(subject + "_selectedGrade");
  if (localStorage.getItem(subject + "_selectedLesson") !== null)
    selectedLesson = localStorage.getItem(subject + "_selectedLesson");

  function handleClick(event) {
    let element = event.target;
    let parentElement = element.parentElement.parentElement;

    if (element.classList.contains("variants__btn_disabled")) return;

    element.classList.toggle("variants__btn_active");

    if (element.classList.contains("variants__btn_active")) {
      [...parentElement.children].map((el) => {
        if (
          el.children[0] !== element &&
          el.children[0].classList.contains("variants__btn_active")
        ) {
          el.children[0].classList.remove("variants__btn_active");
        }
        return null;
      });
      changeInfo(element, parentElement, true);
    } else {
      changeInfo(element, parentElement, false);
    }

    if (element.id === subject + "_individual") {
      if (element.classList.contains("variants__btn_active")) {
        document
          .getElementById(subject + "_ten")
          .classList.remove("variants__btn_disabled");
        document
          .getElementById(subject + "_twenty")
          .classList.remove("variants__btn_disabled");
      }
    }

    if (element.id === subject + "_pair" || element.id === subject + "_group") {
      if (element.classList.contains("variants__btn_active")) {
        document
          .getElementById(subject + "_ten")
          .classList.add("variants__btn_disabled");
        document
          .getElementById(subject + "_twenty")
          .classList.add("variants__btn_disabled");
      } else {
        document
          .getElementById(subject + "_ten")
          .classList.remove("variants__btn_disabled");
        document
          .getElementById(subject + "_twenty")
          .classList.remove("variants__btn_disabled");
      }
    }
  }

  function changeInfo(element, parentElement, isActive) {
    let newInfo = info;
    switch (parentElement.id) {
      case subject + "_class":
        newInfo.class = isActive ? element.textContent : 0;
        changingGrade(element.textContent, isActive);
        changePrice(element.textContent, isActive, "grade");
        break;
      case subject + "_format":
        newInfo.format = isActive ? element.textContent : 0;
        changePrice(element.textContent, isActive, "lesson");
        break;
      case "lessons":
        newInfo.lessons = isActive ? element.textContent : 0;
        break;
      default:
        break;
    }
    setInfo(newInfo);
  }

  function changingGrade(element, isActive) {
    let individual = document.getElementById(subject + "_individual");
    let pair = document.getElementById(subject + "_pair");
    let group = document.getElementById(subject + "_group");
    switch (element) {
      case "1-4 клас":
        if (isActive) {
          individual.classList.add("variants__btn_active");
          pair.classList.add("variants__btn_disabled");
          group.classList.add("variants__btn_disabled");
          let newInfo = info;
          newInfo.format = "Індивідуальне";
          document
            .getElementById(subject + "_ten")
            .classList.remove("variants__btn_disabled");
          document
            .getElementById(subject + "_twenty")
            .classList.remove("variants__btn_disabled");
          setInfo(newInfo);
        } else {
          individual.classList = "variants__btn";
          pair.classList = "variants__btn";
          group.classList = "variants__btn";
        }
        break;
      case "5-10 клас":
        if (pair.classList.contains("variants__btn_disabled"))
          pair.classList.remove("variants__btn_disabled");
        if (group.classList.contains("variants__btn_disabled"))
          group.classList.remove("variants__btn_disabled");
        if (selectedGrade === "1-4 клас") {
          if (pair.classList.contains("variants__btn_active"))
            pair.classList.remove("variants__btn_active");
          if (group.classList.contains("variants__btn_active"))
            group.classList.remove("variants__btn_active");
        }
        break;
      case "11 клас":
        if (isActive) {
          if (pair.classList.contains("variants__btn_disabled"))
            pair.classList.remove("variants__btn_disabled");
          group.classList.add("variants__btn_disabled");
        } else {
          group.classList = "variants__btn";
        }
        if (selectedGrade === "1-4 клас") {
          if (pair.classList.contains("variants__btn_active"))
            pair.classList.remove("variants__btn_active");
          if (group.classList.contains("variants__btn_active"))
            group.classList.remove("variants__btn_active");
        }
        break;
      default:
        break;
    }
  }

  function checkIfSelected() {
    let resGrade = false;
    let resLes = false;
    [...document.getElementById(subject + "_class").children].map((e) => {
      if (e.children[0].classList.contains("variants__btn_active"))
        resGrade = true;
      return null;
    });
    [...document.getElementById(subject + "_format").children].map((e) => {
      if (e.children[0].classList.contains("variants__btn_active"))
        resLes = true;
      return null;
    });

    if (!resGrade) {
      localStorage.setItem(subject + "_selectedGrade", 0);
      selectedGrade = 0;
    }
    if (!resLes) {
      localStorage.setItem(subject + "_selectedLesson", 0);
      selectedLesson = 0;
    }
  }

  function changePriceColor() {
    if (selectedGrade !== 0) {
      document.getElementById(subject + "_ind_price").style.color = "black";
      document.getElementById(subject + "_dual_price").style.color = "black";
      document.getElementById(subject + "_group_price").style.color = "black";

      if (selectedLesson !== 0) {
        document.getElementById(subject + "_trial_price").style.color = "black";
        document.getElementById(subject + "_one_price").style.color = "black";
        document.getElementById(subject + "_ten_price").style.color = "black";
        document.getElementById(subject + "_twenty_price").style.color =
          "black";
      } else {
        document.getElementById(subject + "_trial_price").style.color =
          "transparent";
        document.getElementById(subject + "_one_price").style.color =
          "transparent";
        document.getElementById(subject + "_ten_price").style.color =
          "transparent";
        document.getElementById(subject + "_twenty_price").style.color =
          "transparent";
      }
    } else {
      document.getElementById(subject + "_ind_price").style.color =
        "transparent";
      document.getElementById(subject + "_dual_price").style.color =
        "transparent";
      document.getElementById(subject + "_group_price").style.color =
        "transparent";

      document.getElementById(subject + "_trial_price").style.color =
        "transparent";
      document.getElementById(subject + "_one_price").style.color =
        "transparent";
      document.getElementById(subject + "_ten_price").style.color =
        "transparent";
      document.getElementById(subject + "_twenty_price").style.color =
        "transparent";
    }
  }

  function changePrice(grade, isActive, type) {
    checkIfSelected();

    if (type === "grade") {
      isActive ? (selectedGrade = grade) : (selectedGrade = 0);
      localStorage.setItem(subject + "_selectedGrade", selectedGrade);
    } else if (type === "lesson") {
      isActive ? (selectedLesson = grade) : (selectedLesson = 0);
      localStorage.setItem(subject + "_selectedLesson", selectedLesson);
    }

    if (type === "grade" && isActive) {
      setIndividualPrice(prices[selectedGrade]["Індивідуальне"]);
      setGroupPrice(prices[selectedGrade]["Групове"]);
      setDualPrice(prices[selectedGrade]["Парне"]);

      if (selectedLesson !== 0) {
        setCurrentPrice(prices[selectedGrade][selectedLesson]);
      }
    }

    if (type === "lesson" && isActive) {
      if (selectedGrade !== 0) {
        setCurrentPrice(prices[selectedGrade][selectedLesson]);
      }
    }

    if (grade === "1-4 клас" && isActive) {
      changePrice("Індивідуальне", true, "lesson");
    }

    changePriceColor();
  }

  function openPopup() {
    document.getElementById("overlay").style.display = "block";
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";

    if (window.innerWidth < 1400) {
      document.getElementById("services__popup_" + subject).style.display =
        "block";
    } else {
      document.getElementById("services__popup_" + subject).style.display =
        "flex";
    }

    localStorage.setItem("info_" + subject, JSON.stringify(info));
  }

  // LAYOUT
  useEffect(() => {
    let group = document.getElementById(subject);
    switch (subject) {
      case "math":
        group.classList.add("subject_math");
        break;
      case "eng":
        group.classList.add("subject_eng");
        break;
      case "ukr":
        group.classList.add("subject_ukr");
        break;
      default:
        break;
    }
  }, []); //eslint-disable-line

  function addTitle() {
    let res = null;
    switch (subject) {
      case "math":
        res = "Математика";
        break;
      case "eng":
        res = "Англійська";
        break;
      case "ukr":
        res = "Українська";
        break;
      default:
        break;
    }

    return <>{res}</>;
  }

  function addSubTitle() {
    let res = "";
    switch (subject) {
      case "math":
        res = "математики";
        break;
      case "eng":
        res = "англійської мови";
        break;
      case "ukr":
        res = "української мови";
        break;
      default:
        break;
    }
    return <>{res}</>;
  }

  // SENDING INFO
  function sendInfo() {
    let isEmpty = false;
    Object.keys(info).forEach(function (key, index) {
      if (info[key] === 0) {
        isEmpty = true;
      }
    });
    if (isEmpty) {
      handleSendError();
    } else {
      openPopup();
    }
  }

  const handleSendError = () => {
    setOpen(true);
  };

  const handleSendErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <motion.div
      className="subject"
      id={subject}
      initial={{ opacity: 0, y: -40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        delay: "0." + delay,
      }}
    >
      <div className="subject__title">{addTitle()}</div>
      <div className="subject__subTitle">
        Оберіть варіанти навчання для підготовки з {addSubTitle()}:
      </div>

      <div className="subject__variants">
        <div className="variants">
          <div className="variants__title">Оберіть клас:</div>
          <div className="variants__btnContainer" id={subject + "_class"}>
            <div>
              <div className="variants__btn" onClick={handleClick}>
                1-4 клас
              </div>
            </div>

            <div>
              <div className="variants__btn" onClick={handleClick}>
                5-10 клас
              </div>
            </div>

            <div>
              <div className="variants__btn" onClick={handleClick}>
                11 клас
              </div>
            </div>
          </div>
        </div>
        <div className="variants">
          <div className="variants__title">Оберіть формат навчання:</div>
          <div className="variants__btnContainer" id={subject + "_format"}>
            <div>
              <div
                className="variants__btn"
                onClick={handleClick}
                id={subject + "_individual"}
              >
                Індивідуальне
              </div>
              <div className="variants__price" id={subject + "_ind_price"}>
                {individualPrice} грн / година
              </div>
            </div>
            <div>
              <div
                className="variants__btn"
                onClick={handleClick}
                id={subject + "_pair"}
              >
                Парне
              </div>
              <div className="variants__price" id={subject + "_dual_price"}>
                {dualPrice} грн / година
              </div>
            </div>

            <div>
              <div
                className="variants__btn"
                onClick={handleClick}
                id={subject + "_group"}
              >
                Групове
              </div>
              <div className="variants__price" id={subject + "_group_price"}>
                {groupPrice} грн / година
              </div>
            </div>
          </div>
        </div>
        <div className="variants">
          <div className="variants__title">Оберіть заняття:</div>
          <div className="variants__btnContainer" id="lessons">
            <div>
              <div className="variants__btn" onClick={handleClick}>
                Пробне заняття
              </div>
              <div className="variants__price" id={subject + "_trial_price"}>
                0 грн
              </div>
            </div>

            <div>
              <div className="variants__btn" onClick={handleClick}>
                1 урок
              </div>
              <div className="variants__price" id={subject + "_one_price"}>
                {currentPrice} грн / година
              </div>
            </div>

            <div>
              <div
                className="variants__btn"
                onClick={handleClick}
                id={subject + "_ten"}
              >
                Пакет (10+1)
              </div>
              <div className="variants__price" id={subject + "_ten_price"}>
                {individualPrice * 10} грн
              </div>
            </div>
            <div>
              <div
                className="variants__btn"
                onClick={handleClick}
                id={subject + "_twenty"}
              >
                Пакет (25+3)
              </div>
              <div className="variants__price" id={subject + "_twenty_price"}>
                {individualPrice * 25} грн
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="subject__footer" onClick={sendInfo}>
        Залишити заявку
      </div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleSendErrorClose}
      >
        <Alert
          severity="info"
          open={open}
          autoHideDuration={4000}
          onClose={handleSendErrorClose}
          sx={{
            backgroundColor: "white",
            color: "black",
            width: "100%",
            height: "150%",
          }}
        >
          Будь-ласка, заповніть усі поля
        </Alert>
      </Snackbar>
    </motion.div>
  );
}
