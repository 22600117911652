import React from 'react'
import Slider from 'react-slick'
import {
	ArrowLeft,
	ArrowRight,
	Budak,
	Chepil,
	Ferenc,
	Gavriliak,
	Griciv,
	Karpova,
	Kopin,
	Lecik,
	Nakonechna,
	Nesteruk,
	Panshencko,
	Pirogenko,
	Prohorenkova,
	Zibina,
} from '../../images and icons/images/Index'

import { motion } from 'framer-motion'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { default as NextArrow, default as nextArrow } from './Arrows/NextArrow'
import { default as PrevArrow, default as prevArrow } from './Arrows/PrevArrow'
import './OurCase/OurCase.scss'
import Subtitle from './OurCase/subtitle'

const OurCases = () => {
	let settings = {
		speed: 500,
		autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		infinite: true,
		nextArrow: <NextArrow img={ArrowRight} onClick={nextArrow} />,
		prevArrow: <PrevArrow img={ArrowLeft} onClick={prevArrow} />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					nextArrow: false,
					prevArrow: false,
				},
			},
		],
	}

	return (
		<div className={'container mainTeam blockMargin'}>
			<motion.h2
				className={'title'}
				initial={{ opacity: 0, x: -15 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				transition={{
					delay: '0.2',
				}}
			>
				{'НАША КОМАНДА'}
			</motion.h2>
			<Slider {...settings}>
				<Subtitle
					img={Prohorenkova}
					article={'Прохоренкова Оксана'}
					description={'Математика'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 4 роки' },
						{ heading: '3500+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, механіко-математичний факультет',
						},
					]}
				/>

				<Subtitle
					img={Gavriliak}
					article={'Гавриляк Олена'}
					description={'Математика'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 2 роки' },
						{ heading: '2500+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, механіко-математичний факультет',
						},
					]}
				/>

				<Subtitle
					img={Griciv}
					article={'Гриців Роксолана'}
					description={'English'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 2 роки' },
						{ heading: '2500+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, факультет іноземних мов',
						},
						{ heading: 'Рівень володіння мовою:', title: ' B2+' },
						{ heading: 'Акцент:', title: ' Neutral' },
					]}
				/>

				<Subtitle
					img={Chepil}
					article={'Чепіль Ярина'}
					description={'English'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 1 рік' },
						{ heading: '2000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, факультет іноземних мов',
						},
						{ heading: 'Рівень володіння мовою:', title: ' B2' },
						{ heading: 'Акцент:', title: ' USA' },
					]}
				/>

				<Subtitle
					img={Budak}
					article={'Будак Вікторія'}
					description={'Українська мова'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 2 роки' },
						{ heading: '2000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title:
								' Київський університет ім. Бориса Грінченка,\n' +
								'факультет української філології, культури та мистецтва',
						},
					]}
				/>

				<Subtitle
					img={Lecik}
					article={'Лецик Ірина'}
					description={'Адміністратор'}
					info={[
						{ heading: 'Досвід роботи з клієнтами:', title: ' 2 роки' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, механіко-математичний факультет',
						},
					]}
				/>

				<Subtitle
					img={Karpova}
					article={'Карпова Олеся-Марія'}
					description={'Математика'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 2 роки' },
						{ heading: '2000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title:
								' Львівська політехніка, Факультет компʼютерних наук та інформаційних технологій',
						},
					]}
				/>
				<Subtitle
					img={Nakonechna}
					article={'Наконечна Ірина'}
					description={'Математика'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 3 роки' },
						{ heading: '3000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І.Франка, факультет прикладної математики',
						},
					]}
				/>
				<Subtitle
					img={Pirogenko}
					article={'Піроженко Дарʼя'}
					description={'Математика'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 3 роки' },
						{ heading: '3000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title:
								' КПІ ім. Сікорського, факультет інформатики та \n' +
								'обчислювальної техніки',
						},
					]}
				/>
				<Subtitle
					img={Kopin}
					article={'Копін Наталія'}
					description={'Математика'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 3 роки' },
						{ heading: '3000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І.Франка, механіко-математичний факультет',
						},
					]}
				/>

				<Subtitle
					img={Ferenc}
					article={'Ференц Інна'}
					description={'English'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 2 роки' },
						{ heading: '2500+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, факультет іноземних мов',
						},
						{ heading: 'Рівень володіння мовою:', title: ' B2+' },
						{ heading: 'Акцент:', title: ' Neutral' },
					]}
				/>
				<Subtitle
					img={Zibina}
					article={'Зибіна Катерина'}
					description={'English'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 1 рік' },
						{ heading: '2000+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, факультет іноземних мов',
						},
						{ heading: 'Рівень володіння мовою:', title: ' B2' },
						{ heading: 'Акцент:', title: ' Neutral' },
					]}
				/>

				<Subtitle
					img={Nesteruk}
					article={'Нестерук Анастасія'}
					description={'English'}
					info={[
						{ heading: 'Досвід викладання:', title: ' 2 роки' },
						{ heading: '2500+ год', title: ' репетиторства' },
						{
							heading: 'Освіта:',
							title: ' ЛНУ ім. І. Франка, факультет іноземних мов',
						},
						{ heading: 'Рівень володіння мовою:', title: ' B2+' },
						{ heading: 'Акцент:', title: ' Neutral' },
					]}
				/>
				<Subtitle
					img={Panshencko}
					article={'Панчишин Юлія'}
					description={'Менеджер'}
					info={[{ heading: 'Досвід роботи з клієнтами:', title: ' 3 роки' }]}
				/>
			</Slider>
		</div>
	)
}

export default OurCases
