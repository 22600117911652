import React, { useState } from "react";
import Question from "./Question";
import "./Faq.css";

import { Plus } from "../../images and icons/images/Index";

const Faq = () => {
  const [open1, setOpen1] = useState(false);
  const buttonOpen1 = () => setOpen1(!open1);

  const [open2, setOpen2] = useState(false);
  const buttonOpen2 = () => setOpen2(!open2);

  const [open3, setOpen3] = useState(false);
  const buttonOpen3 = () => setOpen3(!open3);

  const [open4, setOpen4] = useState(false);
  const buttonOpen4 = () => setOpen4(!open4);

  const [open5, setOpen5] = useState(false);
  const buttonOpen5 = () => setOpen5(!open5);

  const [open6, setOpen6] = useState(false);
  const buttonOpen6 = () => setOpen6(!open6);

  return (
    <div className={"container faq"}>
      <h2 className={"mainArticle"}>Типові запитання:</h2>
      <Question
        question={"Як записатись на пробне заняття?"}
        answer={"Для запису на пробне заняття залишайте "}
        selected1={"заявку на сайті."}
        link={"#services"}
        img={Plus}
        open={open1}
        onClick={buttonOpen1}
      />
      <Question
        question={"Скільки коштує одне заняття?"}
        answer={
          "Вартість заняття залежить від трьох чинників: клас, у який ходить Ваша дитина, формат навчання (індивідуальні, парні чи групові заняття) і пакет навчання. Актуальні ціни на навчання можна"
        }
        selected1={"переглянути тут."}
        img={Plus}
        open={open2}
        link={"#services"}
        onClick={buttonOpen2}
      />
      <Question
        question={"Чи можна виконати шкільне домашнє на уроці?"}
        answer={
          "Так. Якщо Ви навчаєтеся індивідуально, то шкільне домашнє можна виконати на занятті, проте обовʼязково попередьте свого вчителя наперед, щоб він вдало склав план заняття і Ви встигли виконати домашнє та опанувати нову тему заняття."
        }
        img={Plus}
        open={open3}
        onClick={buttonOpen3}
      />
      <Question
        question={"А якщо я не зможу прийти на урок?"}
        answer={
          "Ми цінуємо наших клієнтів, тому пропонуємо найвигідніші умови відпрацювання занять!\n" +
          "Якщо Ви навчаєтесь індивідуально, то урок можна перенести на інший зручний день та годину. Головне попередити вчителя не менш як за 12 год до основного уроку."
        }
        answer2={
          "Якщо Ви навчаєтесь у групі, то Вам буде надіслано запис уроку, а також Ви матимете можливість проконсультуватись з вчителем у будь-який зручний час та задати питання щодо теми уроку."
        }
        img={Plus}
        open={open4}
        onClick={buttonOpen4}
      />
      <Question
        question={"Чи можете Ви визначити рівень знань дитини?"}
        answer={
          "Так! Ми БЕЗКОШТОВНО визначимо рівень знань Вашої дитини на першому пробному уроці та складемо індивідуальну програму навчання, щоб якнайшвидше досягнути успіху!"
        }
        img={Plus}
        open={open5}
        onClick={buttonOpen5}
      />
      <Question
        question={"Як розпочати навчання у репетиторському центрі MPLUS?"}
        answer={"Залишіть заявку на"}
        selected1={"пробний БЕЗКОШТОВНИЙ урок"}
        link={"#contact"}
        answer3={
          "на якому вчитель познайомиться з дитиною, визначить рівень її знань та підбере для неї програму згідно Ваших побажань!"
        }
        img={Plus}
        open={open6}
        onClick={buttonOpen6}
      />
    </div>
  );
};

export default Faq;
