import React from 'react';

const Subtitle = ({img,
                      article,
                      description,
                      info,
                  }) => {


    return (
        <div className={'sliderBox'}>
            <img className={'sliderBox__image'} src={img} alt={img}/>
            <h3 className={'sliderBox__article'}>{article}</h3>
            <p className="sliderBox__description">{description}</p>
            <ul className={"sliderBox__subtitle"}>
                {
                    info.map ((e, index)=>{
                        return(
                            <li key={index}><b>{e.heading}</b>{e.title}</li>
                        )
                    })
                }
            </ul>
        </div>
    );
};

export default Subtitle;