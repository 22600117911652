import { React, useEffect } from "react";
import {
  tutoringCenterFirstIcon,
  tutoringCenterSecondIcon,
  tutoringThirdFirstIcon,
} from "../../images and icons/icons";
import "./tutoringcenter.css";
import Form from "./Form/Form";

import { motion } from "framer-motion";

const TutoringCenter = () => {
  function animateNumber(callback, from, to, duration) {
    let start = null,
      animate = (timestamp) => {
        start = start || timestamp;
        let progress = Math.min((timestamp - start) / duration, 1);
        callback(progress * (to - from) + from);
        if (progress < 1) {
          window.requestAnimationFrame(animate);
        }
      };
    window.requestAnimationFrame(animate);
  }

  useEffect(() => {
    animateNumber(
      (value) => {
        document.getElementById("years").textContent = Math.floor(value);
        document.getElementById("years").textContent += "+";
      },
      0,
      4,
      1500,
    );
    animateNumber(
      (value) => {
        document.getElementById("clients").textContent = Math.floor(value);
        document.getElementById("clients").textContent += "+";
        return null;
      },
      0,
      1500,
      1500,
    );
    animateNumber(
      (value) => {
        document.getElementById("hours").textContent = Math.floor(value);
        document.getElementById("hours").textContent += "+";
      },
      0,
      2000,
      1500,
    );
  }, []);

  return (
    <div className="tutoring-wrapper blockMargin">
      <div className="tutoring">
        <div className="tutoring-gradient"></div>
        <div className="tutoring-center container">
          <motion.h1
            className="tutoring-center__title"
            initial={{ opacity: 0, x: -25 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5 }}
          >
            Репетиторський <br /> центр MPLUS
          </motion.h1>
          <p className="tutoring-center__text">
            Ми пропонуємо онлайн-заняття з математики, англійської та
            української мов. Набір триває для 1-11 класів{" "}
            <strong> з можливістю обрати форму навчання.</strong>
          </p>
          <div className="tutoring-center__advantages">
            <div className="tutoring-center__advantage">
              <img
                src={tutoringCenterFirstIcon}
                alt=""
                className="tutoring-center__advantage-img"
              />
              <h2 className="tutoring-center__advantage-title" id="years">
                4+
              </h2>
              <p className="tutoring-center__advantage-text">
                роки роботи <br /> репетиторського <br /> центру
              </p>
            </div>
            <div className="tutoring-center__advantage">
              <img
                src={tutoringCenterSecondIcon}
                alt=""
                className="tutoring-center__advantage-img"
              />
              <h2 className="tutoring-center__advantage-title" id="clients">
                1500+
              </h2>
              <p className="tutoring-center__advantage-text">
                задоволених учнів
              </p>
            </div>
            <div className="tutoring-center__advantage">
              <img
                src={tutoringThirdFirstIcon}
                alt=""
                className="tutoring-center__advantage-img"
              />
              <h2 className="tutoring-center__advantage-title" id="hours">
                2000+
              </h2>
              <p className="tutoring-center__advantage-text">
                годин репетиторства <br />у кожного вчителя
              </p>
            </div>
          </div>
          <Form
            formClass={"tutoring-form"}
            formWrapper={"form-tutoring"}
            formTitle={"Перше заняття"}
            formDecorate={"безкоштовне!"}
            isFirst={true}
          />
        </div>
      </div>
      <Form
        formClass={"tutoring-form__mobile"}
        formWrapper={"form-tutoring"}
        formTitle={"Перше заняття"}
        formDecorate={"безкоштовне!"}
        isFirst={true}
      />
    </div>
  );
};

export default TutoringCenter;
