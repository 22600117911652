import React from "react";
import {
    headerFacebook,
    headerInstagram,
    headerTelegram,
    headerViber,
    tiktokHeader
} from "../../../images and icons/icons";
import "./media.css";

import {instagram, facebook, telegram, viber, tiktok} from "../../../Constants.js"

const Media = () => {
    return (
        <div className="media">
            <a href={instagram} className="header-media__item">
                <img src={headerInstagram} alt="" className="header-media__img"/>
            </a>
            <a href={facebook} className="header-media__item">
                <img src={headerFacebook} alt="" className="header-media__img"/>
            </a>
            <a href={telegram} className="header-media__item">
                <img src={headerTelegram} alt="" className="header-media__img"/>
            </a>
            <a href={viber} className="header-media__item">
                <img src={headerViber} alt="" className="header-media__img"/>
            </a>
            <a href={tiktok} className="header-media__item">
                <img src={tiktokHeader} alt="" className="header-media__img"/>
            </a>
        </div>
    );
};

export default Media;
