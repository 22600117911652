import React from 'react'
import {
	facebook,
	instagram,
	telegram,
	tiktok,
	viber,
} from '../../Constants.js'
import {
	facebook_beige,
	inst_beige,
	tg_beige,
	tiktok_beige,
	viber_beige,
} from '../../images and icons/icons/index'
import './Offers.scss'

import { motion } from 'framer-motion'

export default function Offers() {
	return (
		<div className='offers container blockMargin' id='price'>
			<motion.div
				className='title'
				initial={{ opacity: 0, x: 15 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				transition={{
					delay: '0.2',
				}}
			>
				Акції та пропозиції
			</motion.div>
			<div className='offers__container'>
				<div className='offerBlock offers__block offerBlock_package'>
					<div className='offerBlock__title offerBlock__title_beige'>
						Пакет «10+1»
					</div>
					<div className='offerBlock__subTitle'>
						Оплачуйте 10 занять та отримуйте одне безкоштовно!
					</div>

					<div className='offerBlock__paragraphs'>
						<p>
							3200 грн. – 11 індивідуальних занять для учнів 1–4 класів (290
							грн/год)
						</p>
						<p>
							3600 грн. – 11 індивідуальних занять для учнів 5–10 класів (327
							грн/год)
						</p>
						<p>
							4000 грн. – 11 індивідуальних занять для учнів 11 класів
							(підготовка до ЗНО/НМТ) (363 грн/год)
						</p>
					</div>

					<div className='offerBlock__footer'>
						ВАЖЛИВО! Пакет діє тільки для індивідуальних занять.
					</div>
				</div>

				<div className='offerBlock offers__block offerBlock_package'>
					<div className='offerBlock__title offerBlock__title_beige'>
						Пакет «25+3»
					</div>
					<div className='offerBlock__subTitle'>
						Оплачуйте 25 занять та отримуйте три безкоштовно!
					</div>

					<div className='offerBlock__paragraphs'>
						<p>
							8000 грн. – 28 індивідуальних занять для учнів 1–4 класів (285
							грн/год)
						</p>
						<p>
							9000 грн. – 28 індивідуальних занять для учнів 5–10 класів (321
							грн/год)
						</p>
						<p>
							10000 грн. – 28 індивідуальних занять для учнів 11 класів
							(підготовка до ЗНО/НМТ) (357 грн/год)
						</p>
					</div>

					<div className='offerBlock__footer'>
						ВАЖЛИВО! Пакет діє тільки для індивідуальних занять.
					</div>
				</div>

				<div className='offerBlock offers__block offerBlock_sales'>
					<div className='offerBlock__title offerBlock__title_pink'>
						Реферальна програма
					</div>
					<div className='offerBlock__subTitle'>
						Більше друзів = більше бонусних занять.
					</div>

					<div className='offerBlock__paragraphs'>
						<p>
							Розповідайте друзям та знайомим про репетиторський центр MPLUS.
						</p>
						<p>
							Якщо Ваш товариш запишеться на основну послугу і вкаже Ваше
							прізвище та ім‘я, то Вам буде нараховане одне бонусне заняття.
						</p>
					</div>
				</div>

				<div className='offerBlock offers__block offerBlock_share'>
					<div className='offerBlock__shareTitle'>Поділитись з друзями:</div>
					<div className='offerBlock__media'>
						<a href={instagram}>
							<img src={inst_beige} alt='instagram' />
						</a>
						<a href={facebook}>
							<img src={facebook_beige} alt='facebook' />
						</a>
						<a href={telegram}>
							<img src={tg_beige} alt='telegram' />
						</a>
						<a href={viber}>
							<img src={viber_beige} alt='viber' />
						</a>
						<a href={tiktok}>
							<img src={tiktok_beige} alt='viber' />
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
