import { motion } from 'framer-motion'
import React from 'react'
import './TeachersAdvantages.scss'

// ICONS
import number_01 from '../../images and icons/icons/number_01.svg'
import number_02 from '../../images and icons/icons/number_02.svg'
import number_03 from '../../images and icons/icons/number_03.svg'
import number_04 from '../../images and icons/icons/number_04.svg'
import number_05 from '../../images and icons/icons/number_05.svg'
import roads from '../../images and icons/icons/roads.svg'

export default function TeachersAdvantages() {
	return (
		<div className='advantages blockMargin' id='aboutUs'>
			<div className='title container'>
				Вчителі репетиторського центру MPLUS:
			</div>

			<div className='roadMap advantages__roadMap'>
				<img src={roads} className='roadMap__map' alt='path' />

				<div className='container roadMap__grid'>
					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_01} alt='number_01' />
						<div className='roadMap__text'>Пройшли ретельний відбір</div>
					</motion.div>

					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0.1,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_02} alt='number_02' />
						<div className='roadMap__text'>Успішно склали тестування</div>
					</motion.div>
					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0.2,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_03} alt='number_03' />
						<div className='roadMap__text'>
							Мають чудові навички комунікації та спілкування
						</div>
					</motion.div>
					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0.3,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_04} alt='number_04' />
						<div className='roadMap__text'>Закінчили навчальні курси</div>
					</motion.div>
					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0.4,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_05} alt='number_05' />
						<div className='roadMap__text'>
							Мають досвід роботи з учнями різних класів
						</div>
					</motion.div>
				</div>
			</div>

			<div className='advantages__info container'>
				<div className='advantages__block'>
					<div className='advantages__subTitle advantages__subTitle_beige'>
						Про нас:
					</div>
					<div className='advantages__text'>
						✓ Команда висококваліфікованих фахівців, які допоможуть Вашій дитині
						досягнути мети <br />
						✓ Знаємо як в перші ж хвилини уроку зацікавити дитину
						<br />
						✓ Проводимо заняття з усмішкою
						<br />
						✓ Цікаво пояснюємо складні теми
						<br />
						✓ Розробляємо індивідуальний план роботи з учнем/групою
						<br />
						✓ Заохочуємо нестандартне мислення
						<br />
					</div>
				</div>

				<div className='advantages__block'>
					<div className='advantages__subTitle advantages__subTitle_pink'>
						Особливістю команди вчителів MPLUS:
					</div>

					<div className='advantages__text'>
						Є те, що ми розробляємо індивідуальний план навчання дитини з
						урахуванням всіх Ваших побажань.
					</div>

					<div className='advantages__footer'>
						Внаслідок цього, за короткий проміжок часу наші вчителі надають базу
						знань, яку учень не отримав у школі, швидко та якісно охоплюють
						новий матеріал, щоб дитина вільно почувала себе на уроках.
					</div>
				</div>
			</div>
		</div>
	)
}
