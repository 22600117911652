import { motion } from 'framer-motion'
import { React, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactPhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { baseUrl, botId, encodedToken } from '../../Constants.js'
import loadingHTML from '../../UI/loading/loading.js'
import '../../UI/loading/loading.scss'
import './QuestionForm.scss'

export default function ValidateInputs() {
	let disabledButton = { background: 'grey' }
	const navigate = useNavigate()

	useEffect(() => {
		let container = document.getElementsByClassName('questionForm')[0]
		let myInput = container.getElementsByClassName('form-control')[0]
		myInput.id = 'questionForm__input_phone'

		if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
			if (window.innerWidth > 992) {
				document.getElementById('questionForm__form').classList.add('forSafari')
			}
		}
	}, [])

	function sendQuestion() {
		let name = document.getElementById('questionForm__input_name')
		let phone = document.getElementById('questionForm__input_phone')

		// VALIDATION
		if (name.value === '') {
			name.setCustomValidity('Заповніть це поле.')
			return
		} else if (phone.value === '') {
			name.setCustomValidity('')
			phone.setCustomValidity('Заповніть це поле.')
			return
		}

		name.setCustomValidity('')
		phone.setCustomValidity('')

		SendQuestion(name.value, phone.value)
	}

	function AnimateLoading() {
		let mainDiv = document.getElementById('questionForm__input_loading')
		mainDiv.getElementsByClassName('check')[0].classList =
			'check check-complete success'
		;[...mainDiv.getElementsByClassName('fill')].map(el => {
			el.classList = 'fill fill-complete success'
			return null
		})
		mainDiv.getElementsByClassName('path')[0].classList = 'path path-complete'

		setTimeout(function () {
			mainDiv.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
		}, 5000)
	}
	const {
		register,
		control,
		formState: { errors, isValid },
	} = useForm({ mode: 'onBlur' })
	let redBord = { border: '2px solid red' }
	let blackBord = { border: '2px solid black' }
	function SendQuestion(name, phone) {
		let btnElement = document.getElementById('questionForm__input_btn')
		btnElement.innerHTML = `<div id="questionForm__input_loading">${loadingHTML}</div>`

		const msg = ` У користувача ${name} залишились запитання 
Телефон: ${phone}`

		fetch(baseUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				validator: encodedToken,
				chat_id: botId,
				message: msg,
			}),
		})
			.then(response => response.json())
			.then(data => {
				AnimateLoading()
				navigate('/thank-you')
			})
			.catch(error => {
				btnElement.innerHTML = 'Спробуйте ще раз..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})
	}

	function setValidity(event) {
		if (event.currentTarget.customValidity !== '') {
			event.currentTarget.setCustomValidity('')
		}
	}
	return (
		<div className='questionForm container blockMargin' id='questionForm'>
			<motion.div
				className='title'
				initial={{ opacity: 0, x: 15 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				transition={{
					delay: '0.2',
				}}
			>
				Залишились питання?
			</motion.div>
			<div className='questionForm__subTitle'>
				Залишайте заявку і наш менеджер зв’яжеться з вами протягом 15 хвилин в
				робочий час!
			</div>

			<form className='questionForm__form' id='questionForm__form'>
				<input
					required
					type='text'
					name='name'
					className='questionForm__input'
					id='questionForm__input_name'
					placeholder='Введіть ваше ім’я'
					{...register('name', {
						required: true,
						pattern: new RegExp(/^[a-zA-ZА-яіІїЇҐґєЄ]{4,25}$/),
					})}
					style={errors.name ? redBord : blackBord}
					onChange={setValidity}
				/>
				<Controller
					name='phone'
					control={control}
					rules={{
						required: true,
						valueAsNumber: true,
						pattern: new RegExp(
							/^[\]?[(]?[0-9]{3}[)]?[-\s. ]?[0-9]{3}[-\s.]?[0-9]{4,9}$/
						),
					}}
					style={errors.phone ? redBord : blackBord}
					className='test'
					render={({ field }) => (
						<ReactPhoneInput
							{...field}
							id='questionForm__input_phone'
							country='ua'
							placeholder='Ваш номер телефону'
							inputExtraProps={{
								required: true,
								autoComplete: 'tel',
								valueAsNumber: true,
							}}
						/>
					)}
				/>

				<button
					className='questionForm__btn'
					style={isValid ? null : disabledButton}
					disabled={!isValid}
					type='button'
					onClick={sendQuestion}
					id='questionForm__input_btn'
				>
					ЗАЛИШИТИ ЗАЯВКУ
				</button>
			</form>
		</div>
	)
}
