

const instagram = "https://instagram.com/mplus.center?igshid=OGQ5ZDc2ODk2ZA==";
const ownerInstagram = "https://instagram.com/vladyslava_math?igshid=MjEwN2IyYWYwYw==";
const instagramHighlights = "https://www.instagram.com/stories/highlights/17935941248159803/";
const facebook = "https://www.facebook.com/mplus.tutoring.center";
const telegram = "https://telegram.me/mplus_center";
const tiktok = "https://www.tiktok.com/@mplus.center?_t=8jIoByoV5ld&_r=1";
const viber = "https://tinyurl.com/mram6wad";
const mono = "https://send.monobank.ua/jar/9cgM1t7azP";

const baseUrl = "https://znayeshapi.pythonanywhere.com/api/v1.0/send_bot";
const fileEndpoint = "https://znayeshapi.pythonanywhere.com/api/v1.0/send_file_mplus";
const botId = "-1001815285300";
const encodedToken = "NjI2MjY5NDI0NjpBQUVwSzFsLThoUktwaTdvblZma1BtSTlkeHcxS0JjQWRHSQ==";

export {
  instagram,
  ownerInstagram,
  instagramHighlights,
  facebook,
  telegram,
  viber,
  mono,
  baseUrl,
  fileEndpoint,
  encodedToken,
  botId,
  tiktok
}
