import React from 'react';
import './button.css';

const Button = ({buttonText}) => {
    return (
        <div className="button-wrapper">
            <button className="button">{buttonText}</button>
        </div>
    );
};

export default Button;