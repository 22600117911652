import React from 'react'
import SubjectBlock from './subjectBlock/subjectBlock.js'
import './OurServices.scss'
import Popup from './Popup/Popup.js'
import { baseUrl, encodedToken, botId } from '../../Constants.js'
import loadingHTML from '../../UI/loading/loading.js'
import { useNavigate } from 'react-router-dom'

export default function OurServices() {
	const navigate = useNavigate()

	function AnimateLoading() {
		let mainDiv = document.getElementById('vacancies__input_loading')
		mainDiv.getElementsByClassName('check')[0].classList =
			'check check-complete success'
		;[...mainDiv.getElementsByClassName('fill')].map(el => {
			el.classList = 'fill fill-complete success'
			return null
		})
		mainDiv.getElementsByClassName('path')[0].classList = 'path path-complete'

		setTimeout(function () {
			mainDiv.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
		}, 5000)

		return null
	}

	function sendInfo(data, subject) {
		const msg = `
    Нова заявка на заняття
    Ім'я: ${data.name}
    Клас дитини: ${data.class}
    Предмет: ${data.subject}
    Формат навчання: ${data.format}
    Кількість занять: ${data.lessons}

    Пошта: ${data.email}
    Телефон: ${data.phone}
    `

		let btnElement = document.getElementById(
			'vacancies__input_button_' + subject
		)
		btnElement.innerHTML = `<div id="vacancies__input_loading">${loadingHTML}</div>`

		fetch(baseUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				validator: encodedToken,
				chat_id: botId,
				message: msg,
			}),
		})
			.then(response => response.json())
			.then(data => {
				AnimateLoading()
				navigate('/thank-you')
			})
			.catch(error => {
				btnElement.innerHTML = 'Спробуйте ще раз..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})
	}

	return (
		<div className='services container blockMargin' id='services'>
			<div className='title'>Наші послуги</div>
			<div className='services__subjects'>
				<SubjectBlock subject='math' delay='1' />
				<SubjectBlock subject='eng' delay='2' />
				<SubjectBlock subject='ukr' delay='3' />
			</div>
			<Popup subject='math' sendInfo={sendInfo} />
			<Popup subject='eng' sendInfo={sendInfo} />
			<Popup subject='ukr' sendInfo={sendInfo} />
		</div>
	)
}
