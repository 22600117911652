import { useState } from "react";
import Navigation from "./Navigation/Navigation";
import "./header.css";
import { headerLogo, headerPhone } from "../../images and icons/icons";
import Media from "./Media/Media";
import Button from "./Button/Button";
import BurgerMenu from "./BurgerMenu/BurgerMenu";

import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const Header = () => {
  function copyText(event) {
    let number = event.target.textContent;
    navigator.clipboard.writeText(number);
    setOpen(true);
  }

  const [open, setOpen] = useState(false);

  const handleSendErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="header container">
      <div className="header-firstblock">
        <img src={headerLogo} alt="" className="header-logo" />
        <Navigation />
      </div>
      <div className="header-secondblock">
        <Media />
        <div className="header-phone">
          <img src={headerPhone} alt="" className="header-phone__icon" />
          <div
            type="phone"
            href="+380 (98) 324 72 80"
            className="header-phone__number"
            onClick={copyText}
          >
            +380 (98) 324 72 80
          </div>
        </div>

        <a href="#questionForm">
          <Button buttonText={"Залишити заявку"} />
        </a>
      </div>
      <BurgerMenu />

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleSendErrorClose}
      >
        <Alert
          severity="success"
          open={open}
          autoHideDuration={4000}
          onClose={handleSendErrorClose}
        >
          Номер скопійовано
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Header;
