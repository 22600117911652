import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ThankYou from './pages/ThankYou'
const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
	},
	{
		path: '/thank-you',
		element: <ThankYou />,
	},
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)
