import { motion } from 'framer-motion'
import React from 'react'
import { ownerInstagram } from '../../Constants.js'
import { inst_beige } from '../../images and icons/icons'
import Form from '../TutoringCenter/Form/Form'
import './owner.css'

const Owner = () => {
	return (
		<div className='container blockMargin'>
			<motion.h1
				className='title'
				initial={{ opacity: 0, x: -15 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				transition={{
					delay: '0.2',
				}}
			>
				Інформація про власника:
			</motion.h1>
			<div className='owner-wrapper'>
				<div className='owner-information'>
					<h2 className='owner-name'>Ахременко Владислава</h2>
					<p className='owner-experience'>
						Завершила з відзнакою навчання у Львівському національному
						університеті ім. І. Франка і здобула кваліфікацію: ступінь вищої
						освіти бакалавр та магістр за спеціальністю 014.04 Середня освіта
						(Математика)
					</p>
					<div className='owner-curses'>
						<div className='owner-curses__wrapperYear'>
							<h3 className='owner-curses__title'>Авторський річний курс </h3>
							<p className='owner-curses__info'>
								підготовки до ЗНО/НМТ з математики, який містить:
							</p>
							<ul className='owner-curses__list'>
								<li className='owner-curses__list-item'>• 84 відео-уроки</li>
								<li className='owner-curses__list-item'>
									• 84 робочі зошити з готовими конспектами
								</li>
								<li className='owner-curses__list-item'>
									• 84 домашні завдання у зручній формі
								</li>
								<li className='owner-curses__list-item'>
									• Чат-бот у Telegram та постійний звʼязок з куратором
								</li>
							</ul>
						</div>
						<div className='owner-curses__wrapperAudience'>
							<h3 className='owner-curses__title'>
								Курс підійде для 10-11 класів
							</h3>
							<div className='owner-curses__wrapperMedia'>
								<a style={{ all: 'unset' }} href={ownerInstagram}>
									<img src={inst_beige} alt='' className='owner-curses__img' />
								</a>
								<h3 className='owner-curses__media-title'>
									Деталі можна переглянути в instagram
								</h3>
							</div>
						</div>
					</div>
					<Form
						formClass={'tutoring-form owner-form'}
						formWrapper={'form-tutoring owner-formWrapper'}
						formTitle={'РЕЄСТРУЙТЕСЬ НА КУРС!'}
						isFirst={false}
					/>
				</div>
				<div className='owner-img' />
			</div>
			<Form
				formClass={'tutoring-form__mobile owner-form'}
				style='display:none;'
				formWrapper={'form-tutoring owner-formWrapper'}
				formTitle={'РЕЄСТРУЙТЕСЬ НА КУРС!'}
				isFirst={false}
			/>
		</div>
	)
}

export default Owner
