import { Link } from 'react-router-dom'
import './ThankYou.scss'

const ThankYou = () => {
	return (
		<main className='thank-you'>
			<div>
				<h1>Дякуємо за заявку</h1>
				<p>Ми звʼяжемося з Вами якнайшвидше</p>
				<Link to='/' className='donate__btn'>
					На головну
				</Link>
			</div>
		</main>
	)
}
export default ThankYou
