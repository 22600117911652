import React from 'react'
import feedback_1 from '../../Video/feedback_11.MP4'
import feedback_2 from '../../Video/feedback_2.MP4'
import feedback_3 from '../../Video/feedback_3.mp4'
import playBtn from '../../images and icons/icons/playBtn.svg'
import './Feedbacks.scss'

import feedback1_poster from '../../images and icons/images/feedback1_poster.jpg'
import feedback2_poster from '../../images and icons/images/feedback2_poster.jpg'
import feedback3_poster from '../../images and icons/images/feedback3_poster.jpg'

import { motion } from 'framer-motion'
import Slider from 'react-slick'

import { instagramHighlights } from '../../Constants.js'

import { ArrowLeft, ArrowRight } from '../../images and icons/images/Index'

import {
	default as NextArrow,
	default as nextArrow,
} from '../OurCases/Arrows/NextArrow'
import {
	default as PrevArrow,
	default as prevArrow,
} from '../OurCases/Arrows/PrevArrow'

export default function Feedbacks() {
	let isPlaying = false

	function playVideo(event) {
		let curVideoContainer = event.target
		let VideoContainer = curVideoContainer.classList.contains(
			'feedbacks__videoContainer'
		)
			? curVideoContainer
			: curVideoContainer.parentNode

		let video = VideoContainer.getElementsByTagName('video')[0]
		let playBtn = VideoContainer.getElementsByTagName('img')[0]

		if (video.paused) {
			video.play()
			playBtn.style.display = 'none'

			if (isPlaying !== false && isPlaying.video !== video) {
				isPlaying.video.pause()
				isPlaying.btn.style.display = 'block'
			}

			isPlaying = {
				video: video,
				btn: playBtn,
			}
		} else {
			video.pause()
			playBtn.style.display = 'block'
		}
	}

	let settingsForVideo = {
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		nextArrow: <NextArrow img={ArrowRight} onClick={nextArrow} />,
		prevArrow: <PrevArrow img={ArrowLeft} onClick={prevArrow} />,
		infinite: true,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	}

	return (
		<div className='feedbacks container blockMargin' id='feedbacks'>
			<motion.div
				className='title'
				initial={{ opacity: 0, x: -15 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				transition={{
					delay: '0.2',
				}}
			>
				Відгуки наших учнів
			</motion.div>

			<Slider {...settingsForVideo}>
				<motion.div
					className='feedbacks__videoContainer'
					onClick={playVideo}
					initial={{ opacity: 0, y: -40 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						delay: 0.3,
					}}
				>
					<video className='feedbacks__video' poster={feedback2_poster}>
						<source src={feedback_2} type='video/mp4' />
					</video>
					<img src={playBtn} className='feedbacks__playBtn' alt='play' />
				</motion.div>

				<motion.div
					className='feedbacks__videoContainer'
					onClick={playVideo}
					initial={{ opacity: 0, y: -40 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						delay: 0.4,
					}}
				>
					<video className='feedbacks__video' poster={feedback1_poster}>
						<source src={feedback_1} type='video/mp4' />
					</video>
					<img src={playBtn} className='feedbacks__playBtn' alt='play' />
				</motion.div>

				<motion.div
					className='feedbacks__videoContainer'
					onClick={playVideo}
					initial={{ opacity: 0, y: -40 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						delay: 0.5,
					}}
				>
					<video className='feedbacks__video' poster={feedback3_poster}>
						<source src={feedback_3} type='video/mp4' />
					</video>
					<img src={playBtn} className='feedbacks__playBtn' alt='play' />
				</motion.div>
			</Slider>

			<div className='feedbacks__footer'>
				<div className='feedbacks__text'>
					Більше відгуків ви можете переглянути в нашому Instagram
				</div>
				<a style={{ all: 'unset' }} href={instagramHighlights}>
					<div className='feedbacks__btn'>ПЕРЕГЛЯНУТИ</div>
				</a>
			</div>
		</div>
	)
}
